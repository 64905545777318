import axios from "axios";
import { io } from "socket.io-client";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { MutatingDots } from "react-loader-spinner";
import { useContext, useEffect, useState, KeyboardEvent } from "react";

import { deviceType } from "@app/App";

import styles from "@app/assets/styles/connection.module.scss";

import { PLAYZER_GAME_SERVER_URL } from "@app/config";

import Context from "@app/contexts/Context";
import { GameImages } from "@app/assets/images/game/game";

export default function Connection() {
    const navigate = useNavigate();

    const { setRoomId, setIoSocket, setRoomType, ioSocket } = useContext(Context);

    const [roomIdInput, setRoomIdInput] = useState("");
    const [isValidRoomId, setValidation] = useState<undefined | "wrongChar" | "notFound">();

    // Query
    const joinRoomQuery = useMutation({
        mutationFn: () => {
            return axios.get(`${PLAYZER_GAME_SERVER_URL}/rooms/room-${roomIdInput}`)
        },
        onSuccess: (res) => {
            const room = res.data;
            setRoomType(room.type);
            const socket = io(PLAYZER_GAME_SERVER_URL, { extraHeaders: { "room": room.id }});
            socket.connect();
            setRoomId(roomIdInput);
            setIoSocket(socket);
        },
        onError: (err) => {
            console.error("##### error :", err);
            setRoomIdInput("");
            setValidation("notFound");
        }
    });

    // Functions
    function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            joinRoom();
          }
    }

    function joinRoom() {
        joinRoomQuery.mutate();
    };

    useEffect(() => {
        if (deviceType === "cast") {
            navigate("/lobby");
        }
    }, []);

    useEffect(() => {
        if (ioSocket) {
            ioSocket.emit("tvConnected");
            navigate("/lobby");
        }
    }, [ioSocket])

    const errorText = isValidRoomId ? isValidRoomId === "notFound" ? "La room est introuvable" : "L'ID doit uniquement être composé de chiffre" : "";

    return (
        <div className={styles.connectionContainer}>
            <img src={GameImages.lobby.url} alt=""/>
            <div className={styles.container}>
                Room ID
                <input
                    value={roomIdInput}
                    onKeyDown={handleKeyDown}
                    onChange={e => {
                        let isnum = e.target.value === "" || /^\d+$/.test(e.target.value);
                        if (!isnum && isValidRoomId !== "wrongChar") {
                            setValidation("wrongChar");
                        } else if (isnum && isValidRoomId !== undefined){
                            setValidation(undefined);
                        }
                        setRoomIdInput(e.target.value)
                    }}
                    minLength={5}
                    maxLength={5}
                    />
                <div className={styles.error}>{errorText}</div>
                <div className={styles.buttonWrapper}>
                    {joinRoomQuery.isLoading ? (
                        <MutatingDots
                        visible={true}
                        height="100"
                        width="100"
                        color="#5478D0"
                        secondaryColor="#E4C67F"
                        radius="12.5"
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        />
                    ) : (
                        <button onClick={joinRoom}>Join</button>
                    )}
                </div>
            </div>
        </div>
    );
}
