import axios from "axios";
import useSound from "use-sound";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";

import { DefaultRouteProps } from "@app/App";

import { PLAYZER_GAME_SERVER_URL } from "@app/config";

import CharactersPlaying from "@app/components/CharactersPlaying";

import { CharacterType, User } from "@app/types/user";
import { Answers, ClassicAnswer, IntruderAnswer, Question, RoundType } from "@app/types/question";

import styles from "@app/assets/styles/game.module.scss";

import { GameImages } from "@app/assets/images/game/game.js";
import { CharacterImages } from "@app/assets/images/character/character";

import Context from "@app/contexts/Context";

export default function Game(props: DefaultRouteProps) {
    const { receiverContext } = props;

    const {
        ioSocket,
        setRoomId,
        setIoSocket,
        roomType,
        roomId,
    } = useContext(Context);

    const navigate = useNavigate();
    const [question, setQuestion] = useState<Question | undefined>();
    const [players, setPlayers] = useState<User[]>([]);
    const [answers, setAnswers] = useState<Answers>();
    const [roundType, setRoundType] = useState<RoundType>();
    const [answer, setAnswer] = useState<number>();
    const [allIntruderAnswers, setIntruderAnswers] = useState<number[]>([]);
    const [characterPlaying, setCharacterPlaying] = useState<User>();
    const [timerImage, setTimerImage] = useState(GameImages.timer.initUrl);
    const [backgroudnActiveIndex, setBackgroudnActiveIndex] = useState<0 | 1>(0); // Default background index is 0
    const [videoPlaying, setVideoPlaying] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    // Queries
    const playersQuery = useQuery(["get", "players"], async () => {
            const players: User[] = (await axios.get(`${PLAYZER_GAME_SERVER_URL}/rooms/users/room-${roomId}`)).data;
            setPlayers(players);
        },
        { keepPreviousData: false }
    );

    // Sound
    const soundManager = {
                newRound: useSound(require("../assets/sound/game/characterPresentation.m4a")),
                timer: useSound(require("../assets/sound/timer/timer.m4a")),
                timerLow: useSound(require("../assets/sound/timer/timerLow.m4a")),
                timerEnd: useSound(require("../assets/sound/timer/timerEnd.m4a")),
                question: useSound(require("../assets/sound/game/question.m4a")),
                revealAnswer: useSound(require("../assets/sound/answer/revealAnswer.m4a")),
                buzzer: useSound(require("../assets/sound/game/buzzer.m4a")),
                geniusWinner: useSound(require("../assets/sound/genius/winner.m4a")),
                geniusEndGame: useSound(require("../assets/sound/genius/endGame.m4a")),
                applause: [
                    useSound(require("../assets/sound/audience/applause1.m4a")),
                    useSound(require("../assets/sound/audience/applause2.m4a")),
                    useSound(require("../assets/sound/audience/applause3.m4a")),
                ],
                boo: [
                    useSound(require("../assets/sound/audience/boo1.m4a")),
                    useSound(require("../assets/sound/audience/boo2.m4a")),
                    useSound(require("../assets/sound/audience/boo3.m4a")),
                ],
                laugh: [
                    useSound(require("../assets/sound/audience/laugh1.m4a")),
                    useSound(require("../assets/sound/audience/laugh2.m4a")),
                    useSound(require("../assets/sound/audience/laugh3.m4a")),
                ],
                geniusGood: [
                    useSound(require("../assets/sound/genius/good/good1.m4a")),
                    useSound(require("../assets/sound/genius/good/good2.m4a")),
                    useSound(require("../assets/sound/genius/good/good3.m4a")),
                ],
                geniusWrong: [
                    useSound(require("../assets/sound/genius/wrong/wrong1.m4a")),
                    useSound(require("../assets/sound/genius/wrong/wrong2.m4a")),
                    useSound(require("../assets/sound/genius/wrong/wrong3.m4a")),
                ],
    }

    // Effect
    useEffect(() => {
        function exitRoom() {
            soundManager.geniusEndGame[1].stop();
            soundManager.geniusWinner[1].stop();
            soundManager.newRound[1].stop();
            soundManager.question[1].stop();
            soundManager.revealAnswer[1].stop();
            soundManager.timer[1].stop();
            soundManager.timerLow[1].stop();

            setRoomId(undefined);
            if (ioSocket) {
                ioSocket.emit("tvDisconnected");
                ioSocket.disconnect();
            }
            setIoSocket(undefined);

            if (receiverContext) {
                receiverContext.stop();
            } else {
                navigate("/");
            }
        };

        function answers(answers: Answers, currentRoundType: RoundType) {
            const { reaction } = answers;
            setTimerImage(GameImages.timer.initUrl);
            setBackgroudnActiveIndex(0);

            soundManager.question[1].stop();
            soundManager.revealAnswer[0]();
            soundManager.timer[1].stop();
            soundManager.timerLow[1].stop();

            if (reaction) {
                if (reaction.geniusReaction === "good" && reaction.geniusSoundNumber !== undefined) {
                    soundManager.geniusGood[reaction.geniusSoundNumber][0]();
                } else if (reaction.geniusReaction === "wrong" && reaction.geniusSoundNumber !== undefined) {
                    soundManager.geniusWrong[reaction.geniusSoundNumber][0]();
                }

                if (reaction.publicReaction === "applause" && reaction.publicSoundNumber !== undefined) {
                    soundManager.applause[reaction.publicSoundNumber][0]();
                } else if (reaction.publicReaction === "boo" && reaction.publicSoundNumber !== undefined) {
                    soundManager.boo[reaction.publicSoundNumber][0]();
                } else if (reaction.publicReaction === "laugh" && reaction.publicSoundNumber !== undefined) {
                    setTimeout(() => soundManager.laugh[reaction.publicSoundNumber as number][0](), 1500);
                }
            }

            setAnswers(answers);

            if (currentRoundType === "Intruder") {
                const intruderAnswer = answers as IntruderAnswer;
                setIntruderAnswers(intruderAnswer.answerDisable);
                if (intruderAnswer.userAnswered.lastAnswer === intruderAnswer.wrongAnswer || intruderAnswer.answerDisable.length === 7) {
                    setIntruderAnswers([]);
                }
            }
        };

        function newQuestion(question: Question) {
            setQuestion(question);

            if (question.answers.length > 0) {
                setBackgroudnActiveIndex(1);
                setTimerImage(GameImages.timer.url);
                soundManager.newRound[1].stop();
                soundManager.question[0]();
                soundManager.timer[0]();
            } else {
                playersQuery.refetch();
                setAnswers(undefined);
                setAnswer(undefined);
            }

            if ("userShouldAnswer" in question) {
                setCharacterPlaying(question.userShouldAnswer);
            }
        };

        function userAnswer(user: User, currentRoundType: RoundType) {
            soundManager.buzzer[0]();
            if (currentRoundType === "Intruder") {
                setAnswer(user.lastAnswer);
            }
        }

        function questionEnd(endOfTime: boolean) {
            if (endOfTime) {
                soundManager.timer[1].stop();
                soundManager.timerLow[1].stop();
                soundManager.timerEnd[0]();
            }
        }

        function gameEnded(winner: User, winSoundNumber: number) {
            setQuestion(undefined);
            soundManager.geniusEndGame[0]();
            setTimeout(() => {
                soundManager.geniusWinner[0]();
                setTimeout(() => {
                    setTimeout(() => {
                        navigate(`/game-ended/${winSoundNumber}`);
                    }, 4000);
                }, 2500);
            }, 3500);

            if (ioSocket) {
                ioSocket.off("answers", answers);
                ioSocket.off("timerLow", timerLow);
                ioSocket.off('newRound', newRound);
                ioSocket.off("gameEnded", gameEnded);
                ioSocket.off("deleteRoom", exitRoom);
                ioSocket.off('userAnswer', userAnswer);
                ioSocket.off('questionEnd', questionEnd);
                ioSocket.off("newQuestion", newQuestion);
                ioSocket.off("skip", skip);
            }
        };

        function timerLow() {
            soundManager.timerLow[0]();
        };

        function newRound(type: RoundType) {
            setAnswers(undefined);
            soundManager.newRound[0]();
            setQuestion(undefined);
            setRoundType(type);
            setVideoPlaying(true);
        }

        function skip() {
                
            if (videoRef.current) {
                videoRef.current.pause();
                setVideoPlaying(false);
            }
            
        }

        if (ioSocket) {
            ioSocket.on("answers", answers);
            ioSocket.on("timerLow", timerLow);
            ioSocket.on('newRound', newRound);
            ioSocket.on("gameEnded", gameEnded);
            ioSocket.on("deleteRoom", exitRoom);
            ioSocket.on('userAnswer', userAnswer);
            ioSocket.on('questionEnd', questionEnd);
            ioSocket.on("newQuestion", newQuestion);
            ioSocket.on("skip", skip);
        } else {
            setRoomId(undefined);
            setIoSocket(undefined);
            navigate("/");
        }

        return () => {
            if (ioSocket) {
                ioSocket.off("answers", answers);
                ioSocket.off("timerLow", timerLow);
                ioSocket.off('newRound', newRound);
                ioSocket.off("gameEnded", gameEnded);
                ioSocket.off("deleteRoom", exitRoom);
                ioSocket.off('userAnswer', userAnswer);
                ioSocket.off('questionEnd', questionEnd);
                ioSocket.off("newQuestion", newQuestion);
                ioSocket.off("skip", skip);
            }
        };
    }, [soundManager]);

    useEffect(() => {
        switch (roundType) {
            case "TrueOrFalse":
                if(videoRef.current){
                    videoRef.current.play();
                }
                break;
            case "FourChoices":
                if(videoRef.current){
                    videoRef.current.src = require("../assets/video/fourChoices.mp4");
                    videoRef.current.play();
                }
                break;
            case "Intruder":
                if(videoRef.current){
                    videoRef.current.src = require("../assets/video/intruder.mp4");
                    videoRef.current.play();
                }
        }
    }, [roundType])
    
    let gridStyles: React.CSSProperties = { gridTemplateColumns: "40% 40%" };

    if (roundType === "Intruder") {
        gridStyles = {
            gridTemplateColumns: "25% 25% 25% 25%",
        }

        if (question && "userShouldAnswer" in question && answers === undefined && question?.answers.length < 1) {
            gridStyles = {
                gridTemplateColumns: "100%",
            }
        }
    }

    return (
        <div className={styles.gameContainer}>
            {videoPlaying ? (
                <video
                    ref={videoRef}
                    src={require("../assets/video/trueOrFalse.mp4")}
                    autoPlay={true}
                />
            ) : (
            <>

            <img src={GameImages.background[backgroudnActiveIndex]} alt=""/>
            <div className={styles.wrapper}>
                <div className={styles.charactersWrapper}>
                    <CharactersPlaying
                        players={players}
                        question={question}
                        playersAnswer={answers}
                        roundType={roundType}
                        ioSocket={ioSocket}
                    />
                    <img className={styles.timer} src={timerImage} alt=""/>
                </div>
                <div className={styles.questionWrapper}>
                    <img className={styles.questionInsert} src={GameImages.question.url} alt=""/>
                    <div className={styles.questionInsertText}>
                        {question?.question}
                    </div>
                    <div className={styles.grid} style={gridStyles}>
                        {question && "userShouldAnswer" in question && answers === undefined && question?.answers.length < 1 ? (
                            <div className={styles.userShouldAnswerText}>
                                C'est au tour de {characterPlaying?.name} de répondre
                            </div>
                        ) : question && question.answers.map((answerText, answerIndex) => {
                            let color = "#fff";
                            let image = GameImages.answer.url;
                            let characterAnswer: CharacterType[] = [];
                            let disable = allIntruderAnswers.includes(answerIndex) && answers === undefined;

                            if (answers) {
                                if (roundType !== "Intruder") {
                                    const classicPlayersAnswer = answers as ClassicAnswer;
                                    const player = classicPlayersAnswer.usersAnswer[0];

                                    if (classicPlayersAnswer.correctAnswer === answerIndex) {
                                        color = "#000";
                                        image = GameImages.goodAnswer.url;
                                    } else if (roomType === "solo" && player.answer === answerIndex && classicPlayersAnswer.correctAnswer !== player.answer) {
                                        image = GameImages.wrongAnswer.url;
                                    }

                                    classicPlayersAnswer.usersAnswer.forEach(
                                        (playerAnswered) => {
                                          if (
                                            playerAnswered.userId &&
                                            playerAnswered.answer === answerIndex
                                          ) {
                                            const playerCharacter = players.find(
                                              (player) => player.id === playerAnswered.userId,
                                            )?.character;
                
                                            if (playerCharacter) {
                                              characterAnswer.push(playerCharacter);
                                            }
                                          }
                                        },
                                      );
                                } else {
                                    const intruderAnswer = answers as IntruderAnswer;
                                    if (intruderAnswer.userAnswered?.lastAnswer === undefined || (intruderAnswer.userAnswered.lastAnswer === intruderAnswer.wrongAnswer && intruderAnswer.wrongAnswer === answerIndex)) {
                                        image = GameImages.wrongAnswer.url;
                                    } else if (intruderAnswer.userAnswered.lastAnswer === answerIndex) {
                                        color = "#000";
                                        image = GameImages.goodAnswer.url;
                                    }
                                }
                            } else if (answer !== undefined) {
                                if (answer !== answerIndex) {
                                    disable = true; 
                                }
                            }
                            

                            return (
                                <div key={answerIndex} className={styles.buttonWrapper} style={{ height: roundType === "TrueOrFalse" ? "50%" : "100%" }}>
                                    {characterAnswer.map((characterType, index) => {
                                        const characterImg = CharacterImages.allCharacter.find((character) => character.type === characterType)?.url;
                                        return (
                                            <div
                                                key={index}
                                                className={styles.characterAnswer}
                                                style={{
                                                    background: `url(${characterImg}) center / contain no-repeat`,
                                                    left: `${index * 6}cqh`
                                                }}
                                            />
                                        )
                                    })}
                                    <div
                                        key={answerIndex}
                                        className={styles.button}
                                        style={{
                                            background: `url(${image}) center / contain no-repeat`,
                                            color: color,
                                            opacity: disable ? 0.5 : 1,
                                        }}
                                    >
                                        {answerText}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            </>)}
        </div>
    );
}
